import type { TAuthor } from '@/types/authorType';
import type { TMkpoClass } from '@/types/mkpoTypes';
import type { ModelFileFormats, RegisteredModelTypes, TModelPartList } from '@/types/modelType';
import { CompareType } from '@/store/modules/search/state';

export enum SearchStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  QUEUED = 'QUEUED'
}

export type TSearchInfo = {
  compareType?: CompareType;
  compareResultsCount?: number;
  deleted?: boolean;
  filters?: [{
    mkpoClassId: number | null;
    registeredModelTypeId: RegisteredModelTypes;
  }];
  id?: number;
  model?: {
    amountOfModelParts: number;
    author: TAuthor;
    comment: string;
    conversionPercentage: number;
    createdAt: number;
    details: string;
    fileName: string;
    id?: number;
    mkpoClass: TMkpoClass;
    mkpoClassGrandParent: TMkpoClass;
    mkpoClassParent: TMkpoClass;
    modelData: string;
    modelFileFormat: {
      format: string;
      id: ModelFileFormats;
    };
    modelPartList: TModelPartList[];
    modelUuid: string;
    modifiedAt: number;
    patentId: string;
    registeredModelType: {
      id: RegisteredModelTypes;
      type: string;
    };
    requestId: string;
    status: string;
    variantId: string;
    version: number;
  };
  progress?: number;
  startTime?: number;
  status?: SearchStatus;
  user?: TAuthor;
  modelModelCount?: number;
  modelPartCount?: number;
  partModelCount?: number;
  partPartCount?: number;
};
