import { computed, defineComponent, PropType, ref, inject } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import ROUTE_NAMES from '@/router/route.names.json';
import type { TModelData } from '@/types/modelType';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import AdditionalInfoDropdown from '@/components/Model/AdditionalInfoDropdown/AdditionalInfoDropdown.vue';
import { getAuthImage } from '@/utils/getAuthImage';
import { getModelImageUrl, getModelPartImageUrl } from '@/components/Model/utils/getImageUrl';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox/BaseCheckbox.vue';
import { Ref } from '@vue/reactivity';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import { RegisteredModelTypes, TAddInfo } from '@/types/modelType';
import ComparedImages from '@/components/ComparedImages/ComparedImages.vue';
import ReportCheckbox from '@/components/Search/components/ReportCheckbox/ReportCheckbox.vue';
import { getMkpoClassGrandParent, getMkpoClassParent, getMkpoName } from '@/utils/getMkpoClasses';
import { useStore } from 'vuex';
import { getTypeNameByModelType } from '@/utils/getNameByModelType';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { useRouter } from 'vue-router';
import { checkPermissionsForLink } from '@/utils/checkPermissionsForLink';
import { hasRmtsAccess, isAdmin, isInternalUser } from '@/utils/rolesUtils';

export default defineComponent({
  name: 'CompareCard',

  components: {
    BaseButton,
    BaseIconButton,
    ModelLabel,
    AdditionalInfoDropdown,
    BaseCheckbox,
    ComparedImages,
    ReportCheckbox
  },

  props: {
    modelData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    partData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    compareModelData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    comparePartData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    comparePercents: {
      type: Number,
      required: true
    },
    isAddedToReport: {
      type: Boolean,
      required: true
    },
    reportUnitId: {
      type: Number
    },
    index: {
      type: Number,
      default: 0
    },
    searchUnitId: {
      type: Number,
      required: true
    }
  },

  setup(props, { emit }) {
    const addInfoRef = ref();
    const addTechInfoRef = ref();
    const isChecked = ref(false);
    const isOpenInfo = inject<Ref>('isOpenInfo');
    const isPostingUnit = ref(false);

    const { state } = useStore();
    const { push, resolve } = useRouter()

    const cardClass = computed(() => {
      return `compare-card-wrapper ${!isOpenInfo?.value ? 'p-col-6' : 'p-col-12'}`;
    });

    const percents = computed(() => {
      return (props.comparePercents * 100).toFixed(0);
    });

    const toggleTechDropdown = () => {
      addInfoRef.value.addInfoRef.setInVisible();
      addTechInfoRef.value.addInfoRef.isVisible ? addTechInfoRef.value.addInfoRef.setInVisible() : addTechInfoRef.value.addInfoRef.setVisible();
    };

    const toggleDropdown = () => {
      addTechInfoRef.value.addInfoRef.setInVisible();
      addInfoRef.value.addInfoRef.isVisible ? addInfoRef.value.addInfoRef.setInVisible() : addInfoRef.value.addInfoRef.setVisible();
    };

    const getAuthImageForCard = (modelId: number, partUuid?: string) => {
      return partUuid ? getAuthImage(getModelPartImageUrl(modelId, partUuid)) : getAuthImage(getModelImageUrl(modelId));
    };

    const compareHandler = () => {
      emit('clickCompare');
    };

    const addInfo = computed(() => {
      const info: TAddInfo[] = [];
      if (props.compareModelData.registeredModelType?.id === RegisteredModelTypes.INDUSTRIAL_MODEL) {
        info.splice(0, 0,
          {
            name: 'Класс МКПО',
            text: (props.compareModelData.mkpoClassGrandParent && getMkpoClassGrandParent(props.compareModelData))
          },
          {
            name: 'Подкласс МКПО',
            text: (props.compareModelData.mkpoClassParent && getMkpoClassParent(props.compareModelData))
          },
          {
            name: 'Наименование',
            text: (props.compareModelData.mkpoClass && getMkpoName(props.compareModelData))
          }
        );
      }
      info.splice(3, 0,
        { name: 'Номер заявки', text: (props.compareModelData.requestId && getModelValue(props.compareModelData.requestId)) },
        {
          name: getTypeNameByModelType(props.compareModelData.registeredModelType!.id),
          text: (props.compareModelData.patentId && getModelValue(props.compareModelData.patentId))
        }
      );
      return info;
    });

    // TODO вынести все подобное в отдельный компонент
    const additionalTechInfo = computed(() => {
      const techData = [
        { name: 'Дата поступления модели', text: `${createdAt(props.compareModelData.createdAt)} МСК` },
        { name: 'Пользователь, загрузивший модель', text: props.compareModelData?.author?.login },
        { name: 'Имя файла', text: (props.compareModelData.fileName) }
      ];
      if (isAdmin(state.users.user) || isInternalUser(state.users.user)) {
        techData.push({
          name: 'Универсальный уникальный идентификатор',
          text: (props.compareModelData.modelUuid)
        });
      }
      return techData;
    });

    const handleClickRequestLink = (event: MouseEvent) => {
      checkPermissionsForLink(
        event,
        state.users?.user,
        [props.compareModelData.registeredModelType!.id],
        () => { push({ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: props.compareModelData.requestId! } }); },
        () => {
          const routeData = resolve({ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: props.compareModelData.requestId! } });
          window.open(routeData.href, '_blank');
        },
        () => { appendToastMessage(FORM_DICTIONARY.NO_PERMISSIONS); }
      );
    };

    return {
      isInternalUser,
      hasRmtsAccess,
      handleClickRequestLink,
      addTechInfoRef,
      toggleTechDropdown,
      getTypeNameByModelType,
      additionalTechInfo,
      state,
      isPostingUnit,
      addInfo,
      isOpenInfo,
      cardClass,
      percents,
      getAuthImageForCard,
      toggleDropdown,
      addInfoRef,
      getModelValue,
      ROUTE_NAMES,
      compareHandler,
      isChecked
    };
  }
});
