import { computed, defineComponent, onMounted, onUnmounted, ref, watch, provide } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import InfoHeadForPage from '@/components/InfoHeadForPage/InfoHeadForPage.vue';
import moment from 'moment';
import BaseProgressBar from '@/components/BaseComponents/BaseProgressBar/BaseProgressBar.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import ModelComparePopUp from '@/components/Search/components/ModelComparePopUp/ModelComparePopUp.vue';
import { SEARCH_ACTIONS_TYPE } from '@/store/names/action.name';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { useStore } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { PAGINATOR_MUTATIONS_TYPE, SEARCH_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import BackButton from '@/components/BackButton/BackButton.vue';
import type { TModelData } from '@/types/modelType';
import { SearchStatus } from '@/types/serachCompareTypes';
import ChangeShowTypeButton from '@/components/ChangeShowTypeButton/ChangeShowTypeButton.vue';
import CompareModelList from '@/components/Search/components/СompareModelList/CompareModelList.vue';
import setPaginatorAndFiltersFromQuery from '@/components/Model/utils/setPaginatorAndFiltersFromQuery';
import _ from 'lodash';
import type { TSearchedData } from '@/store/modules/search/state';
import { MODEL_TYPE } from '@/dictionaries/modelData/model.type';
import SearchInfoCard from '@/components/Search/components/SearchInfoCard/SearchInfoCard.vue';
import { RegisteredModelTypes } from '@/types/modelType';
import { getMkpoText } from '@/components/Search/utils/getMkpoText';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import CompareTypeFilters from '@/components/Search/components/CompareTypeFilters/CompareTypeFilters.vue';
import ROUTE_NAMES from '@/router/route.names.json';
import ReportSearchTable from '@/components/ReportSearchTable/ReportSearchTable.vue';
import { ShowType } from '@/store/modules/paginator/state';
import BasePaginator from '@/components/BaseComponents/BasePaginator/BasePaginator.vue';
import type { TEventPaginator } from '@/types/eventTypes';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { switchError } from '@/services/utils/switchError';
import { hasRmtsAccess } from '@/utils/rolesUtils';
import { CompareType } from '@/store/modules/search/state';

export default defineComponent({
  name: 'PageSearch',
  components: {
    BaseProgressBar,
    BaseButton,
    InfoHeadForPage,
    BasePopUp,
    ModelComparePopUp,
    BaseLoader,
    BackButton,
    ChangeShowTypeButton,
    CompareModelList,
    SearchInfoCard,
    CompareTypeFilters,
    ReportSearchTable,
    BasePaginator
  },

  setup() {
    const route = useRoute();
    const comparePopUpRef = ref();
    const compareData = ref<TModelData>({});
    const comparePartUuid = ref();
    const mainPartUuid = ref();
    const isMounted = ref(true);
    const mkpoText = ref('');
    const { push } = useRouter();

    const handleOpenPopUp = () => {
      comparePopUpRef.value.handleModal();
    };
    const { state, dispatch, commit } = useStore();

    const searchData = computed(() => state.search.searchData);

    const createdAt = computed(() => {
      return searchData.value.startTime ? moment(searchData.value.startTime).format('DD.MM.YYYY HH:mm:ss') : '-';
    });

    const resultCount = computed(() => {
      return (state.search.searchResult.totalElements || 0)
    });

    const isSliderVisible = computed(() => {
      return (
        (searchData.value.status === SearchStatus.PROCESSING || searchData.value.status === SearchStatus.QUEUED) &&
        !state.search.isLoadingSearch
      );
    });

    const getSearchResult = async() => {
      try {
        await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.GET_COMPARE_RESULTS}`, route.params.id);
      } catch (err) {
        return switchError(err, FORM_DICTIONARY.SEARCH_NOT_FOUND);
      }
    };
    const getDebouncedSearchResult = _.debounce(getSearchResult, 300);

    const getCompareResult = async() => {
      if (state.search.searchData.status === SearchStatus.COMPLETED) {
        commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_SEARCH}`, true);
        await getDebouncedSearchResult();
      } else {
        setTimeout(async() => {
          if (isMounted.value) {
            try {
              await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.GET_COMPARE_TASK}`, route.params.id);
              if (hasRmtsAccess(state.users.user, [searchData.value.model?.registeredModelType?.id])) {
                await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.GET_REPORT_DATA}`, searchData.value.model.requestId);
              }
              commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_SEARCH}`, false);
              await getCompareResult();
              mkpoText.value = await getMkpoText(searchData.value?.filters[0]?.mkpoClassId);
            } catch (err) {
              return switchError(err, FORM_DICTIONARY.SEARCH_NOT_FOUND);
            }
          }
        }, 1000);
      }
    };

    onMounted(async() => {
      setPaginatorAndFiltersFromQuery(route.query);
      await getCompareResult();
    });

    onUnmounted(() => {
      isMounted.value = false;
      commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_COMPARE_TASK}`, {});
      commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_COMPARE_RESULTS}`, {});
      commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_SEARCH}`, true);
    });

    watch(() => state.paginator.paginator, () => {
      if (state.search.searchData.status === SearchStatus.COMPLETED) {
        getDebouncedSearchResult();
      }
    });

    watch(() => state.search.searchFilter, () => {
      if (state.search.searchData.status === SearchStatus.COMPLETED) {
        getDebouncedSearchResult();
      }
    });

    const hasResult = computed(() => {
      return !(!state.search?.searchResult?.content?.length &&
        searchData.value.status === SearchStatus.COMPLETED &&
        !state.search.isLoadingSearch);
    });

    const searchOptions = computed(() => {
      let additionalInfo = '';
      if (searchData.value?.filters?.length) {
        const searchedId = searchData.value?.filters[0]?.registeredModelTypeId;
        if (searchedId === RegisteredModelTypes.INDUSTRIAL_MODEL) {
          if (searchData.value?.filters[0]?.mkpoClassId) {
            additionalInfo = `, ${mkpoText.value}`;
          } else {
            additionalInfo = ', Все классы МКПО';
          }
        }
        const result = Object.values(MODEL_TYPE).find(item => (item.value === searchedId));
        return result?.label + additionalInfo;
      } else {
        return '-';
      }
    });

    const clickCompare = (activeItem: TSearchedData) => {
      const compareModelData = state.search.searchResult.content.find((item: TSearchedData) => {
        return item.modelTo.id === activeItem.modelTo.id;
      });

      if (activeItem.modelPartFrom?.uuid) {
        mainPartUuid.value = activeItem.modelPartFrom?.uuid;
      } else {
        mainPartUuid.value = undefined;
      }

      if (activeItem.modelPartTo?.uuid) {
        comparePartUuid.value = activeItem.modelPartTo.uuid;
      } else {
        comparePartUuid.value = undefined;
      }

      compareData.value = compareModelData.modelTo;
      handleOpenPopUp();
    };

    const goToReport = () => {
      push({
        name: ROUTE_NAMES.PAGE_REQUEST,
        params: { id: searchData.value.model.requestId },
        query: { isReport: 'true' }
      });
    };

    const isOpenInfo = ref(true);
    provide('isOpenInfo', isOpenInfo);
    const changeOpenCard = (bool: boolean) => {
      isOpenInfo.value = bool;
    };

    const onChangePagination = (event: TEventPaginator) => {
      commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
        size: event.rows,
        page: event.page
      });
    };

    const compareType = computed(() =>
      (state.search.searchData.compareType === CompareType.NEURAL ? 'Нейросеть' : 'Аналитика')
    );

    return {
      compareType,
      hasRmtsAccess,
      defaultPaginatorSettings,
      onChangePagination,
      ShowType,
      changeOpenCard,
      isSliderVisible,
      goToReport,
      mainPartUuid,
      comparePartUuid,
      compareData,
      clickCompare,
      searchOptions,
      hasResult,
      state,
      searchData,
      route,
      createdAt,
      resultCount,
      comparePopUpRef,
      handleOpenPopUp,
      SearchStatus
    };
  }
});
