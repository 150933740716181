<template>
 <div :class="cardClass">
  <div class="compare-card">
    <div class="main-img">
      <div class="label-wrapper">
        <ModelLabel
          :modelTypeId="modelData.registeredModelType?.id"
        />
        <div
          v-if="modelData.variantId !== null"
          class="version-label">
          {{ modelData.variantId }}
        </div>
      </div>
      <div class="block-name">Исходная модель</div>
      <ComparedImages
        :model-data="modelData"
        :part-data="partData"
      />
    </div>
    <div class="compare-img">
      <AdditionalInfoDropdown
        :data="addInfo"
        ref="addInfoRef"
        animateName="slide"
      />
      <AdditionalInfoDropdown
        :data="additionalTechInfo"
        ref="addTechInfoRef"
        animateName="slide"
      />
      <div class="label-wrapper">
        <ModelLabel
          :modelTypeId="compareModelData.registeredModelType?.id"
        />
        <div
          v-if="modelData.variantId !== null"
          class="version-label">
          {{ modelData.variantId }}
        </div>
      </div>
      <div class="block-name">Похожая модель</div>
      <ComparedImages
        :model-data="compareModelData"
        :part-data="comparePartData"
      />
    </div>
    <div class="info-block">
      <div :class="`percent-label ui-title-medium model-${compareModelData.registeredModelType?.id}label`">{{ percents }}</div>
      <div class="info-wrapper">
        <div class="request-block">
          <router-link
            @click="handleClickRequestLink($event)"
            class="value"
            target="_blank"
            :to="{ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: compareModelData?.requestId }}"
          >
            <div class="ui-tooltip">
              <div class="value-line">
                {{ getModelValue(compareModelData.requestId) }}
                <div class="tooltip-content">{{ getModelValue(compareModelData.requestId) }}</div>
              </div>
            </div>
          </router-link>
          <div class="text">Номер заявки</div>
        </div>
        <div class="patient-block">
          <div class="value">
            <div class="ui-tooltip">
              <div class="value-line">
                {{ getModelValue(compareModelData.patentId) }}
                <div class="tooltip-content">{{ getModelValue(compareModelData.patentId) }}</div>
              </div>
            </div>
          </div>
          <div class="text">{{ getTypeNameByModelType(compareModelData.registeredModelType.id) }}</div>
        </div>
      </div>
      <div>
        <template
          v-if="!state.search.reportData.isFinished &&
          hasRmtsAccess(state.users.user, [modelData.registeredModelType?.id]) &&
          !isInternalUser(state.users.user)"
        >
          <ReportCheckbox
            :model-data="modelData"
            :part-data="partData"
            :compare-part-data="comparePartData"
            :compare-model-data="compareModelData"
            :compare-percents="comparePercents"
            :is-added-to-report="isAddedToReport"
            :report-unit-id="reportUnitId"
            :index="index"
            :search-unit-id="searchUnitId"
          />
        </template>
        <div class="action-block">
          <div class="add-info-wrapper">
            <div
              @click="toggleDropdown"
              class="add-info-button"
              v-tooltip.top="'О заявке'"
            >
              <i class="pi pi-info-circle"></i>
            </div>
            <div
              @click="toggleTechDropdown"
              class="add-info-button"
              v-tooltip.top="'Техническая информация'"
            >
              <img alt="img" src="@/assets/icons/tech_info.svg" />
            </div>
          </div>
          <div class="buttons-wrapper">
            <BaseIconButton
              tool-tip-text="Сравнение моделей"
              @clicked="compareHandler"
            >
              <template v-slot:button-image>
                <img class="small-picture" alt="img" src="@/assets/icons/compare.svg">
              </template>
            </BaseIconButton>
            <BaseIconButton
              tool-tip-text="Перейти к просмотру"
              is-target-blank
              :href="{
                name: ROUTE_NAMES.PAGE_MODEL_VIEW,
                params: { id: compareModelData.modelUuid },
                query: { partUuid: comparePartData?.uuid }
              }"
            >
              <template v-slot:button-image>
                <img alt="img" src="@/assets/icons/open_model.svg">
              </template>
            </BaseIconButton>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script src="./CompareCard.ts" lang="ts"/>
<style src="./CompareCard.scss" lang="scss"/>
