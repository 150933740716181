<template>
  <div
    class="p-grid ui-p-grid"
  >
    <div
      class="p-col-12 p-d-flex p-jc-end ui-title-small"
      v-if="state.search.reportData.isFinished"
    >
      Отчет зафиксирован
    </div>
    <template
      v-for="(item, index) in models.content"
      :key="`${index}-${item.id}`"
    >
      <CompareCard
        :model-data="item.modelFrom"
        :part-data="item.modelPartFrom || {}"
        :compare-model-data="item.modelTo"
        :compare-part-data="item.modelPartTo || {}"
        :compare-percents="item.distance"
        :is-added-to-report="item.isAddedToReport"
        :report-unit-id="item.reportUnitId"
        :searchUnitId="item.id"
        @clickCompare="clickCompare(item)"
      />
    </template>
  </div>
</template>

<script src="./CompareModelList.ts" lang="ts"/>
<style src="./CompareModelList.scss" lang="scss"/>
