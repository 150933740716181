<template>
  <InfoHeadForPage
    page-name="Поиск"
    class-name="search-head"
    :has-bottom-block="false"
  >
    <template v-slot:pre-name-block>
      <BackButton/>
    </template>
    <template v-slot:content-top>
      <div class="head-info-content view-head">
        <div class="p-d-flex p-justify-end search-info">
          <div class="search-options-block">
            <span class="text">Критерий поиска:</span>
            <span class="created-at">{{ searchOptions }}</span>
          </div>
          <div class="search-date">
            <span class="text">Дата поиска:</span>
            <span class="created-at">{{ createdAt }} МСК</span>
          </div>
          <div class="search-date">
            <span class="text">Алгоритм поиска:</span>
            <span class="">{{ state.search.isLoadingSearch ? '-' : compareType }}</span>
          </div>
          <div class="db-size">
            <span class="text">Показано результатов:</span>
            <span class="count"> {{ resultCount }}</span>
          </div>
          <BaseButton
            v-if="hasRmtsAccess(state.users.user, [state.search?.searchData.model?.registeredModelType?.id])"
            outlined
            label="Перейти к отчету"
            @clicked="goToReport"
            :disabled="state.search.isLoadingSearch"
          />
        </div>
      </div>
    </template>
  </InfoHeadForPage>
  <div class="search-content">
    <SearchInfoCard @changeOpenCard="changeOpenCard($event)" />
    <div class="result-block">
      <div class="p-grid" >
        <div class="p-col-12 ">
          <div class="top-wrapper">
            <div class="filters-block">
              <span>Отображать результаты:</span>
              <CompareTypeFilters />
            </div>
            <ChangeShowTypeButton :disabled="state.search.isPostingReportUnit" />
          </div>
        </div>
      </div>
      <BaseLoader v-if="state.search.isLoadingSearch" />
      <CompareModelList
        @clickCompare="clickCompare($event)"
        v-if="!!state.search?.searchResult?.content?.length && (state.paginator.showType === ShowType.CARDS)"
      />
      <ReportSearchTable
        is-for-search
        v-if="state.paginator.showType === ShowType.TABLE"
      />
      <div class="p-col-12">
        <BasePaginator
          :total-records="state.search.searchResult.totalElements"
          @changed="onChangePagination($event)"
          :rows-per-page-options="defaultPaginatorSettings.selectSize"
          :size-rows="state.paginator.paginator.size"
          :first-page-prop="state.paginator.paginator.page"
          :total-pages="state.search.searchResult.totalPages"
        />
      </div>
      <div
        v-if="isSliderVisible"
        class="no-content-block"
      >
        <BaseProgressBar
          label="Ищем похожие модели..."
          :value="searchData.progress"
        />
      </div>
      <div
        v-if="!hasResult"
        class="no-content-block"
      >
        <img alt="not found" src="@/assets/images/no_results.svg"/>
        <div class="ui-title-large">Похожих моделей не найдено</div>
      </div>
    </div>
  </div>
  <BasePopUp ref="comparePopUpRef" class-name="compare-pop-up">
    <template v-slot:content>
      <ModelComparePopUp
        :main-data="state.search.searchData.model"
        :main-part-uuid="mainPartUuid"
        :compare-data="compareData"
        :compare-part-uuid="comparePartUuid"
        :is-loading="state.search.isLoadingSearch"
      />
    </template>
  </BasePopUp>
</template>

<script src="./PageSearch.ts" lang="ts"/>
<style src="./PageSearch.scss" lang="scss"/>
