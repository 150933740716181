<template>
  <div class="compare-filter-wrapper">
   <BaseCheckbox
     name="model-model"
     :label="`модель / модель (${search.modelModelCount || 0})`"
     value="1"
     :is-checked="activeFilters.showModelModel"
     @clicked="clickFilter(UiSearchFilter.MODEL_MODEL)"
     :disabled="!search.modelModelCount"
   />
    <BaseCheckbox
      name="model-detail"
      :label="`модель / деталь (${search.modelPartCount || 0})`"
      value="2"
      :is-checked="activeFilters.showModelPart"
      @clicked="clickFilter(UiSearchFilter.MODEL_DETAIL)"
      :disabled="!search.modelPartCount"
    />
    <BaseCheckbox
      name="detail-model"
      :label="`деталь / модель (${search.partModelCount || 0})`"
      value="3"
      :is-checked="activeFilters.showPartModel"
      @clicked="clickFilter(UiSearchFilter.DETAIL_MODEL)"
      :disabled="!search.partModelCount"
    />
    <BaseCheckbox
      name="detail-detail"
      :label="`деталь / деталь (${search.partPartCount || 0})`"
      value="4"
      :is-checked="activeFilters.showPartPart"
      @clicked="clickFilter(UiSearchFilter.DETAIL_DETAIL)"
      :disabled="!search.partPartCount"
    />
  </div>
</template>

<script src="./CompareTypeFilters.ts" lang="ts"/>
<style src="./CompareTypeFilters.scss" lang="scss"/>
