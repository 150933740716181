<template>
  <BaseInfoCard
    title="Исходная модель"
    @changeOpenCard="changeOpenCard($event)"
  >
    <template v-slot:additional-buttons>
      <BaseIconButton
        tool-tip-text="Перейти к просмотру"
        :href="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: searchData.model?.modelUuid }}"
        is-target-blank
      >
        <template v-slot:button-image>
          <img alt="model-preview" src="@/assets/icons/open_model.svg">
        </template>
      </BaseIconButton>
    </template>
    <template v-slot:content>
      <div class="img-block">
        <div class="label-block">
          <ModelLabel :model-type-id="searchData.model?.registeredModelType.id"/>
        </div>
        <BaseAuthCardImg
          :data="searchData.model"
          v-if="!!searchData?.model?.id"
        />
      </div>
      <div class="info-block">
        <div
          class="info-line"
          v-for="(item, index) in addInfo"
          :key="index"
        >
          <span class="name">{{ item.name }}</span>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
    </template>
  </BaseInfoCard>
</template>

<script src="./SearchInfoCard.ts" lang="ts"/>
<style src="./SearchInfoCard.scss" lang="scss"/>
