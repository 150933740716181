import { computed, defineComponent, onUnmounted, ref } from 'vue';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox/BaseCheckbox.vue';
import { UiSearchFilter } from '@/types/searchFilters';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { SEARCH_ACTIONS_TYPE } from '@/store/names/action.name';
import { SEARCH_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { defaultSearchFiltersValue } from '@/store/modules/search/state';

export default defineComponent({
  name: 'CompareTypeFilters',

  components: {
    BaseCheckbox
  },

  setup() {
    const activeFilters = ref({
      showModelModel: true,
      showModelPart: false,
      showPartModel: false,
      showPartPart: false
    });

    const { dispatch, commit, state } = useStore();

    const changeFilters = (type: UiSearchFilter) => {
      switch (type) {
        case UiSearchFilter.MODEL_MODEL:
          activeFilters.value.showModelModel = !activeFilters.value.showModelModel;
          break;
        case UiSearchFilter.MODEL_DETAIL:
          activeFilters.value.showModelPart = !activeFilters.value.showModelPart;
          break;
        case UiSearchFilter.DETAIL_MODEL:
          activeFilters.value.showPartModel = !activeFilters.value.showPartModel;
          break;
        case UiSearchFilter.DETAIL_DETAIL:
          activeFilters.value.showPartPart = !activeFilters.value.showPartPart;
          break;
      }
    };

    const clickFilter = async(type: UiSearchFilter) => {
      changeFilters(type);
      dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.SET_SEARCH_FILTERS}`, activeFilters.value);
    };

    onUnmounted(() => {
      commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_SEARCH_FILTERS}`, defaultSearchFiltersValue);
    });

    const search = computed(() => state.search.searchData);

    return {
      search,
      UiSearchFilter,
      activeFilters,
      clickFilter
    };
  }
});
