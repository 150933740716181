import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { RegisteredModelTypes } from '@/types/modelType';
import type { TAddInfo } from '@/types/modelType';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import type { TSearchedData } from '@/store/modules/search/state';
import CompareCard from '@/components/Search/components/CompareCard/CompareCard.vue';

export default defineComponent({
  name: 'CompareModelList',

  components: {
    CompareCard,
    BaseLoader
  },

  emits: ['clickCompare'],

  setup(_, { emit }) {
    const { state } = useStore();

    const models = computed(() => {
      return state.search.searchResult;
    });

    const clickCompare = (item: TSearchedData) => {
      emit('clickCompare', item);
    };

    const setDetailCompareInfo = (item: TSearchedData) => {
      const result: TAddInfo[] = [];
      if (item.modelPartFrom) {
        result.push({ name: 'modelPartFrom uuid', text: `${item.modelPartFrom.uuid}` }, { name: 'modelPartFrom name', text: `${item.modelPartFrom.name}` });
      }
      if (item.modelPartTo) {
        result.push({ name: 'modelPartTo uuid', text: `${item.modelPartTo.uuid}` }, { name: 'modelPartTo name', text: `${item.modelPartTo.name}` });
      }
      return result;
    };

    return {
      setDetailCompareInfo,
      clickCompare,
      RegisteredModelTypes,
      models,
      defaultPaginatorSettings,
      state
    };
  }
});
