import { computed, defineComponent } from 'vue';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import { useStore } from 'vuex';
import ROUTE_NAMES from '@/router/route.names.json';
import moment from 'moment';
import BaseAuthCardImg from '@/components/BaseComponents/BaseAuthCardImg/BaseAuthCardImg.vue';
import BaseInfoCard from '@/components/BaseComponents/BaseInfoCard/BaseInfoCard.vue';

export default defineComponent({
  name: 'SearchInfoCard',

  components: {
    BaseIconButton,
    ModelLabel,
    BaseAuthCardImg,
    BaseInfoCard
  },

  setup(_, { emit }) {
    const { state } = useStore();

    const searchData = computed(() => state.search.searchData);

    const createdAt = computed(() => {
      return searchData.value?.model?.createdAt
        ? moment(searchData.value.model.createdAt).format('DD.MM.YYYY HH:mm:ss') : '-';
    });

    const changeOpenCard = (bool: boolean) => {
      emit('changeOpenCard', bool);
    };

    const addInfo = computed(() => {
      return [
        {
          name: 'Номер заявки',
          value: searchData.value.model?.requestId
        },
        {
          name: 'Заявитель',
          value: searchData.value.model?.author.login
        },
        {
          name: 'Дата поступления модели',
          value: `${createdAt.value} МСК`
        }
      ];
    });

    return {
      changeOpenCard,
      searchData,
      ROUTE_NAMES,
      addInfo
    };
  }
});
