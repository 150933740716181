import { ModelsHttpService } from '@/services/modelsService';
import { getValueWithZero } from '@/components/Model/utils/getValueWithZero';

const modelsHttpService = new ModelsHttpService();

export const getMkpoText = async(mkpoId: number | null) => {
  if (!mkpoId) {
    return '';
  }
  const { data } = await modelsHttpService.getMkpoValuesForFilter(mkpoId);
  if (data.selected['3']) {
    const res = `${getValueWithZero(data.selected['1'].classIndex)}-${getValueWithZero(data.selected['2'].classIndex)} - ${data.selected['3'].name}`;
    return res;
  }
  if (data.selected['2']) {
    const res = `${getValueWithZero(data.selected['1'].classIndex)} - ${data.selected['2'].name}`;
    return res;
  } else {
    const res = `${data.selected['1'].name}`;
    return res;
  }
};
